@import 'vars';
@import 'fonts';

.first-screen-home {
	min-height: 100vh;
	width: 100%;
	padding: 180px 0 30px;
	display: flex;

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center; }

	.first-screen-content {
		display: flex; }
	h1 {
		font-size: 38px;
		color: #32495b;
		letter-spacing: 0.7px;
		font-weight: 900;
		margin-bottom: 27px; }
	p {
		color: #617789;
		font-size: 20px;
		letter-spacing: 0.7px;
		line-height: 1.6; }




	.progress_line {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&__in {
			position: relative;
			width: 100%;
			height: 100%; }
		.line_green {
			position: absolute;
			width: 550px;
			height: 3px;
			bottom: 35px;
			left: 32%;
			transform-origin: 0 0;
			transform: rotate(-44.2deg);

			&__in {
				position: relative;
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, rgba(230,57,53,1) 0%,rgba(151,189,66,1) 17%,rgba(148,194,67,1) 85%,rgba(250,250,250,.5) 100%); } }

		.circle_green_1 {
			position: absolute;
			left: 186px;
			top: 50%;
			transform: translateY(-50%);
			width: 9px;
			height: 9px;
			display: inline-block;
			border-radius: 50%;
			background-color: #94c243;
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 24px;
				height: 24px;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: rgba(#94c243, .29); } }

		.circle_green_2 {
			position: absolute;
			left: 268px;
			top: 50%;
			transform: translateY(-50%);
			width: 9px;
			height: 9px;
			display: inline-block;
			border-radius: 50%;
			background-color: #94c243;
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 24px;
				height: 24px;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: rgba(#94c243, .29); }
			&:after {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 36px;
				height: 36px;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: rgba(#94c243, .29); } }
		.circle_green_3 {
			position: absolute;
			left: 425px;
			top: 50%;
			transform: translateY(-50%);
			width: 9px;
			height: 9px;
			display: inline-block;
			border-radius: 50%;
			background-color: #94c243;
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 24px;
				height: 24px;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: rgba(#94c243, .29); }
			&:after {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 36px;
				height: 36px;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: rgba(#94c243, .29); }
			&__in {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: inline-block;
				width: 100%;
				height: 100%;
				&:after {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					width: 45px;
					height: 45px;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: rgba(#94c243, .29); } } }

		.line_red-first {
			position: absolute;
			width: 180px;
			max-width: 0;
			height: 3px;
			bottom: 35px;
			left: calc(32% - 309px);
			transform-origin: 0 0;
			transform: rotate(-30.5deg);
			background: linear-gradient(to left, rgba(230,57,53,.6) 0%,rgba(254,248,248,0) 57%,rgba(255,255,255,0) 59%); }

		.line_red {
			position: absolute;
			width: 180px;
			height: 3px;
			bottom: 35px;
			left: calc(32% - 180px);
			transform-origin: 100% 100%;
			transform: rotate(31deg);

			&__in {
				position: relative;
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, rgba(230,57,53,.6) 0%,rgba(230,57,53,1) 100%); }

			.circle_red_1 {
				position: absolute;
				left: -4px;
				top: 50%;
				transform: translateY(-50%);
				width: 8px;
				height: 8px;
				display: inline-block;
				border-radius: 50%;
				background-color: #e63935; }

			.circle_red_2 {
				position: absolute;
				left: 65px;
				top: 50%;
				transform: translateY(-50%);
				width: 8px;
				height: 8px;
				display: inline-block;
				border-radius: 50%;
				background-color: #e63935;
				&:before {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					width: 18px;
					height: 18px;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: rgba(#e63935, .29); } }

			.circle_red_3 {
				position: absolute;
				left: calc(100% - 6px);
				top: 50%;
				transform: translateY(-50%);
				width: 12px;
				height: 12px;
				display: inline-block;
				border-radius: 50%;
				background-color: #e63935;
				&:before {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					width: 20px;
					height: 20px;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: rgba(#e63935, .29); }
				&:after {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					width: 28px;
					height: 28px;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: rgba(#e63935, .29); } } }
		.anim_line_1 {}
		.anim_line_2,
		.anim_line_3 {
			max-width: 0; }

		.anim_circle {
			opacity: 0; } }




	.bl_left {
		flex-basis: 455px;
		display: flex;
		flex-direction: column; }

	.bl_right {
		min-height: 408px;
		position: relative;
		display: flex;
		padding-top: 54px;
		flex-basis: calc(100% - 455px); }

	.person1 {
		flex-basis: 55%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-left: 68px;
		&__in {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
			border-radius: 50%;
			opacity: 0;
			background-color: #e8e8e8; }

		&__wr {
			width: 214px;
			height: 214px;
			position: relative; }

		.cloud_1 {
			position: absolute;
			z-index: 5;
			bottom: -58px;
			left: 42px;
			will-change: transform; }

		.cloud_1_1 {
			bottom: 6px;
			left: -62px;
			will-change: transform; }



		.cloud_2 {
			position: absolute;
			z-index: 5;
			top: 25px;
			left: -143px;
			will-change: transform; }

		.cloud_3 {
			position: absolute;
			z-index: 5;
			top: -87px;
			left: -78px;
			will-change: transform; }

		.cloud_4 {
			position: absolute;
			z-index: 5;
			top: -92px;
			left: 38px;
			will-change: transform; }

		.cloud_anim_2,
		.cloud_anim_3 {
			opacity: 0; }
		.anim_stagger {
			opacity: 0; }

		.p1_img {
			position: absolute;
			top: 20px;
			left: 50%;
			opacity: 0;
			margin-left: -58px;
			z-index: 2; }
		.p1_nametable_img {
			opacity: 0;
			position: absolute;
			z-index: 1;
			top: -18px;
			left: calc(50% - 106px); } }

	.person2 {
		flex-basis: 45%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		.p2_in_wr {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			overflow: hidden;
			margin-left: -14px; }
		&__in {
			position: relative;
			width: 264px;
			height: 264px;
			opacity: 0;
			border-radius: 50%;
			background-color: #d6f490; }

		&__wr {
			position: relative;
			width: 300px;
			height: 264px;
			margin-right: 35px; }

		.p2_img {
			position: absolute;
			top: 20px;
			opacity: 0;
			left: calc(40% - 128px); }
		.p2_hand_img {
			position: absolute;
			bottom: 45px;
			opacity: 0;
			right: 4px; }
		.p2_nametable_img {
			position: absolute;
			z-index: 1;
			left: calc(50% - 134px);
			opacity: 0;
			bottom: -9px; }

		.green_tooltip_1 {
			position: absolute;
			z-index: 5;
			left: -35px;
			top: -98px; }
		.green_tooltip_1_2 {
			position: absolute;
			z-index: 5;
			left: 8px;
			top: -5px; }

		.green_tooltip_3 {
			position: absolute;
			z-index: 5;
			left: -108px;
			bottom: 70px; }

		.anim_green {
			opacity: 0; }
		.anim_green_2 {
			opacity: 0;
			transform-origin: 100% 100%; } }

	.bttn__wr {
		display: flex;
		justify-content: center;
		margin: 50px 0 30px; }

	.mob_img {
		display: none; } }

.btn_let {
	margin-top: 58px;
	display: flex;
	height: 54px;
	max-width: 434px;
	color: #fff;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	border-radius: 9px;
	position: relative;
	z-index: 3;
	span {
		content: '';
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 9px;
		background: linear-gradient(to bottom, #58b0f4 0%,#58b0f4 0%,#58b0f4 0%,#58b0f4 0%,#31a3dd 59%,#0f97c9 100%,#207cca 100%);
		z-index: -3; }
	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 9px;
		background: linear-gradient(to bottom, #54aef1 59%,#54aef1 100%);
		opacity: 0;
		transition: opacity .3s;
		z-index: -1; }
	&:hover {
		color: #fff;
		&:after {
			opacity: 1; } }
	&:before {
		content: '\f075';
		font-family: $font-awesome;
		font-weight: 900;
		position: absolute;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 37px;
		height: 37px;
		border-radius: 50%;
		background: #54aef1;
		right: 13px;
		top: 50%;
		transform: translateY(-50%); } }

.btn_video {
	background: #fff;
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 50px;
	padding: 0 90px 0 30px;
	box-shadow: 0 33px 54px 0 rgba(125, 161, 187, 0.26);
	position: relative;

	transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);

	&:hover {
		box-shadow: 0 15px 24px 0 rgba(125, 161, 187, 0.26);

		.icon {
			border: 7px solid #fff;
			background: #3399cc;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

			&:before {
				background: #3399cc;
				transform: scale(0, 0); } }

		.btn_label_big {
			color: #3399cc; } }



	.btn_label {
		color: #afb0b1;
		font-size: 14px;
		transition: color .3s;
		line-height: 1.5;

		&_big {
			text-transform: uppercase;
			color: #9fc14e;
			font-size: 20px;
			font-weight: 900; } }

	.icon {
		width: 61px;
		height: 61px;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 300ms;
		border: 1px solid #dcdcdc;

		&:before {
			transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
			content: "";
			width: 43px;
			height: 43px;
			border-radius: 50%;
			background: #8ab206;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto; }

		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			left: 4px;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			border-left: 10px solid #ffffff;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent; } } }

.link_wr {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0 60px; }
.link_maintenance {
	font-size: 18px;
	color: #1d9bd1;
	font-weight: 600;
	position: relative;
	line-height: 1;
	transition: color .3s;
	&:before {
		position: absolute;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		content: '\f1cd';
		font-weight: 400;
		font-family: $font-awesome; }
	&:after {
		position: absolute;
		content: '';
		top: 100%;
		height: 1px;
		opacity: 1;
		width: 100%;
		transition: opacity .3s;
		left: 0;
		background-color: #1d9bd1; }
	&:hover {
		color: #9fc14e;
		&:after {
			opacity: 0; } } }

.btn_request {
	font-size: 16px; }
.btn_roсket {
	color: #32495b;
	transition: all .3s;
	font-family: Lato,sans-serif;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	overflow: hidden;
	height: 40px;
	font-size: 16px;
	position: relative;
	margin-left: 0;
	margin-right: 10px;
	padding: 0 10px 0 40px;
	border: 2px solid #50adee;
	&:before {
		content: '';
		position: absolute;
		left: 10px;
		top: 0;
		z-index: -1;
		bottom: 0;
		height: 25px;
		width: 25px;
		text-align: center;
		margin: auto;
		color: #fff;
		background: #50adee;
		border-radius: 50%;
		transition: all .3s; }

	&:hover {
		span {
			color: #fff; }
		&:before {
			transform: scale(15); } }

	span {
		display: inline-block;
		color: #50adee;
		font-size: 16px;
		&:before {
			content: '\f135';
			font-weight: 900;
			font: normal normal normal 14px/1 FontAwesome;
			position: absolute;
			left: 10px;
			top: 0;
			bottom: 0;
			height: 25px;
			width: 25px;
			line-height: 25px;
			text-align: center;
			margin: auto;
			color: #fff;
			background: transparent;
			border-radius: 50%;
			transition: all .3s; } } }


@import 'media-first-scrin-home';
