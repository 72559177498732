@media only screen and (max-width : 1230px) and (min-width : 1200px) {
	.slick_menu {
		.menu {
			margin-left: 40px; } }
	.btn_roсket {
		margin-right: 5px; } }

@media only screen and (max-width : 1200px) {
	.slick_menu .nav .bl_btn {
		.btn_request {
			border: 2px solid #ff9801;
			&:hover {
				background-color: transparent;
				color: #ff9801;
				&:after {
					color: #ff9801!important; } } }

		.btn_roсket {
			background-color: #50adee;
			&:before {
				content: '\f135';
				font-weight: 400;
				z-index: 1;
				left: 0;
				width: 100%;
				height: 100%;
				background: transparent;
				display: flex;
				justify-content: center;
				align-items: center;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: 28px; }
			&:hover {
				background-color: transparent;
				color: #50adee;
				&:before {
					color: #50adee;
					transform: scale(1); } } } }




	.first-screen-home {
		.btn_let {
			align-self: center;
			width: 100%; }
		.first-screen-content {
			flex-direction: column;
			align-items: center;
			.bl_left {
				text-align: center;
				flex-basis: auto; }

			.bl_right {
				flex-basis: auto;
				width: 100%;
				max-width: 795px;
				margin: 90px auto 0; } } } }


@media only screen and (max-width : 992px) {
	.first-screen-home {
		.person1,
		.person2,
		.progress_line {
			display: none; }

		.first-screen-content {
			.bl_right {
				text-align: center;
				display: block;
				margin-top: 0;
				min-height: 150px; } }

		.mob_img {
			display: inline-block; } } }

@media only screen and (max-width : 480px) {
	.first-screen-home {
		.btn_let {
			font-size: 18px;
			padding-right: 25px; } } }

@media only screen and (min-height : 800px) {
	.first-screen-home {
		min-height: auto; } }
