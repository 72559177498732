@charset "UTF-8";
@font-face {
  font-family: "roboto-example";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-example";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype"); }

@font-face {
  font-family: "nimbus";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Nimbussanl-bol/NimbusSanL-Bol.eot");
  src: url("../fonts/Nimbussanl-bol/NimbusSanL-Bol.eot?#iefix") format("embedded-opentype"), url("../fonts/Nimbussanl-bol/NimbusSanL-Bol.woff") format("woff"), url("../fonts/Nimbussanl-bol/NimbusSanL-Bol.ttf") format("truetype"); }

@font-face {
  font-family: "digital";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Digital/Digital-7Mono.eot");
  src: url("../fonts/Digital/Digital-7Mono.eot?#iefix") format("embedded-opentype"), url("../fonts/Digital/Digital-7Mono.woff") format("woff"), url("../fonts/Digital/Digital-7Mono.ttf") format("truetype"); }

@font-face {
  font-family: "oracle";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Oracle/OraqleScript.eot");
  src: url("../fonts/Oracle/OraqleScript.eot?#iefix") format("embedded-opentype"), url("../fonts/Oracle/OraqleScript.woff") format("woff"), url("../fonts/Oracle/OraqleScript.ttf") format("truetype"); }

@font-face {
  font-family: "lato-black";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Black/Lato-Black.eot");
  src: url("../fonts/Lato/Lato-Black/Lato-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Black/Lato-Black.woff") format("woff"), url("../fonts/Lato/Lato-Black/Lato-Black.ttf") format("truetype"); }

@font-face {
  font-family: "lato-bold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Bold/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold/Lato-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "lato-heavy";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Heavy/Lato-Heavy.eot");
  src: url("../fonts/Lato/Lato-Heavy/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Heavy/Lato-Heavy.woff") format("woff"), url("../fonts/Lato/Lato-Heavy/Lato-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: "lato-medium";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Medium/Lato-Medium.eot");
  src: url("../fonts/Lato/Lato-Medium/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Medium/Lato-Medium.woff") format("woff"), url("../fonts/Lato/Lato-Medium/Lato-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "lato-regular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Regular/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "lato-semibold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-Semibold/Lato-Semibold.eot");
  src: url("../fonts/Lato/Lato-Semibold/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Semibold/Lato-Semibold.woff") format("woff"), url("../fonts/Lato/Lato-Semibold/Lato-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: "lato-semiboldItalic";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lato/Lato-SemiboldItalic/Lato-SemiboldItalic.eot");
  src: url("../fonts/Lato/Lato-SemiboldItalic/Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-SemiboldItalic/Lato-SemiboldItalic.woff") format("woff"), url("../fonts/Lato/Lato-SemiboldItalic/Lato-SemiboldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Comic-Sans-MS";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Comic/ComicSansMS.eot");
  src: url("../fonts/Comic/ComicSansMS.eot?#iefix") format("embedded-opentype"), url("../fonts/Comic/ComicSansMS.woff") format("woff"), url("../fonts/Comic/ComicSansMS.ttf") format("truetype"); }

.first-screen-home {
  min-height: 100vh;
  width: 100%;
  padding: 180px 0 30px;
  display: flex; }
  .first-screen-home .container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .first-screen-home .first-screen-content {
    display: flex; }
  .first-screen-home h1 {
    font-size: 38px;
    color: #32495b;
    letter-spacing: 0.7px;
    font-weight: 900;
    margin-bottom: 27px; }
  .first-screen-home p {
    color: #617789;
    font-size: 20px;
    letter-spacing: 0.7px;
    line-height: 1.6; }
  .first-screen-home .progress_line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .first-screen-home .progress_line__in {
      position: relative;
      width: 100%;
      height: 100%; }
    .first-screen-home .progress_line .line_green {
      position: absolute;
      width: 550px;
      height: 3px;
      bottom: 35px;
      left: 32%;
      transform-origin: 0 0;
      transform: rotate(-44.2deg); }
      .first-screen-home .progress_line .line_green__in {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #e63935 0%, #97bd42 17%, #94c243 85%, rgba(250, 250, 250, 0.5) 100%); }
    .first-screen-home .progress_line .circle_green_1 {
      position: absolute;
      left: 186px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #94c243; }
      .first-screen-home .progress_line .circle_green_1:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: rgba(148, 194, 67, 0.29); }
    .first-screen-home .progress_line .circle_green_2 {
      position: absolute;
      left: 268px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #94c243; }
      .first-screen-home .progress_line .circle_green_2:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: rgba(148, 194, 67, 0.29); }
      .first-screen-home .progress_line .circle_green_2:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: rgba(148, 194, 67, 0.29); }
    .first-screen-home .progress_line .circle_green_3 {
      position: absolute;
      left: 425px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #94c243; }
      .first-screen-home .progress_line .circle_green_3:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: rgba(148, 194, 67, 0.29); }
      .first-screen-home .progress_line .circle_green_3:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: rgba(148, 194, 67, 0.29); }
      .first-screen-home .progress_line .circle_green_3__in {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 100%;
        height: 100%; }
        .first-screen-home .progress_line .circle_green_3__in:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: 45px;
          height: 45px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(148, 194, 67, 0.29); }
    .first-screen-home .progress_line .line_red-first {
      position: absolute;
      width: 180px;
      max-width: 0;
      height: 3px;
      bottom: 35px;
      left: calc(32% - 309px);
      transform-origin: 0 0;
      transform: rotate(-30.5deg);
      background: linear-gradient(to left, rgba(230, 57, 53, 0.6) 0%, rgba(254, 248, 248, 0) 57%, rgba(255, 255, 255, 0) 59%); }
    .first-screen-home .progress_line .line_red {
      position: absolute;
      width: 180px;
      height: 3px;
      bottom: 35px;
      left: calc(32% - 180px);
      transform-origin: 100% 100%;
      transform: rotate(31deg); }
      .first-screen-home .progress_line .line_red__in {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(230, 57, 53, 0.6) 0%, #e63935 100%); }
      .first-screen-home .progress_line .line_red .circle_red_1 {
        position: absolute;
        left: -4px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background-color: #e63935; }
      .first-screen-home .progress_line .line_red .circle_red_2 {
        position: absolute;
        left: 65px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background-color: #e63935; }
        .first-screen-home .progress_line .line_red .circle_red_2:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: 18px;
          height: 18px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(230, 57, 53, 0.29); }
      .first-screen-home .progress_line .line_red .circle_red_3 {
        position: absolute;
        left: calc(100% - 6px);
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background-color: #e63935; }
        .first-screen-home .progress_line .line_red .circle_red_3:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(230, 57, 53, 0.29); }
        .first-screen-home .progress_line .line_red .circle_red_3:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: 28px;
          height: 28px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(230, 57, 53, 0.29); }
    .first-screen-home .progress_line .anim_line_2,
    .first-screen-home .progress_line .anim_line_3 {
      max-width: 0; }
    .first-screen-home .progress_line .anim_circle {
      opacity: 0; }
  .first-screen-home .bl_left {
    flex-basis: 455px;
    display: flex;
    flex-direction: column; }
  .first-screen-home .bl_right {
    min-height: 408px;
    position: relative;
    display: flex;
    padding-top: 54px;
    flex-basis: calc(100% - 455px); }
  .first-screen-home .person1 {
    flex-basis: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 68px; }
    .first-screen-home .person1__in {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
      opacity: 0;
      background-color: #e8e8e8; }
    .first-screen-home .person1__wr {
      width: 214px;
      height: 214px;
      position: relative; }
    .first-screen-home .person1 .cloud_1 {
      position: absolute;
      z-index: 5;
      bottom: -58px;
      left: 42px;
      will-change: transform; }
    .first-screen-home .person1 .cloud_1_1 {
      bottom: 6px;
      left: -62px;
      will-change: transform; }
    .first-screen-home .person1 .cloud_2 {
      position: absolute;
      z-index: 5;
      top: 25px;
      left: -143px;
      will-change: transform; }
    .first-screen-home .person1 .cloud_3 {
      position: absolute;
      z-index: 5;
      top: -87px;
      left: -78px;
      will-change: transform; }
    .first-screen-home .person1 .cloud_4 {
      position: absolute;
      z-index: 5;
      top: -92px;
      left: 38px;
      will-change: transform; }
    .first-screen-home .person1 .cloud_anim_2,
    .first-screen-home .person1 .cloud_anim_3 {
      opacity: 0; }
    .first-screen-home .person1 .anim_stagger {
      opacity: 0; }
    .first-screen-home .person1 .p1_img {
      position: absolute;
      top: 20px;
      left: 50%;
      opacity: 0;
      margin-left: -58px;
      z-index: 2; }
    .first-screen-home .person1 .p1_nametable_img {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: -18px;
      left: calc(50% - 106px); }
  .first-screen-home .person2 {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; }
    .first-screen-home .person2 .p2_in_wr {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      margin-left: -14px; }
    .first-screen-home .person2__in {
      position: relative;
      width: 264px;
      height: 264px;
      opacity: 0;
      border-radius: 50%;
      background-color: #d6f490; }
    .first-screen-home .person2__wr {
      position: relative;
      width: 300px;
      height: 264px;
      margin-right: 35px; }
    .first-screen-home .person2 .p2_img {
      position: absolute;
      top: 20px;
      opacity: 0;
      left: calc(40% - 128px); }
    .first-screen-home .person2 .p2_hand_img {
      position: absolute;
      bottom: 45px;
      opacity: 0;
      right: 4px; }
    .first-screen-home .person2 .p2_nametable_img {
      position: absolute;
      z-index: 1;
      left: calc(50% - 134px);
      opacity: 0;
      bottom: -9px; }
    .first-screen-home .person2 .green_tooltip_1 {
      position: absolute;
      z-index: 5;
      left: -35px;
      top: -98px; }
    .first-screen-home .person2 .green_tooltip_1_2 {
      position: absolute;
      z-index: 5;
      left: 8px;
      top: -5px; }
    .first-screen-home .person2 .green_tooltip_3 {
      position: absolute;
      z-index: 5;
      left: -108px;
      bottom: 70px; }
    .first-screen-home .person2 .anim_green {
      opacity: 0; }
    .first-screen-home .person2 .anim_green_2 {
      opacity: 0;
      transform-origin: 100% 100%; }
  .first-screen-home .bttn__wr {
    display: flex;
    justify-content: center;
    margin: 50px 0 30px; }
  .first-screen-home .mob_img {
    display: none; }

.btn_let {
  margin-top: 58px;
  display: flex;
  height: 54px;
  max-width: 434px;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 9px;
  position: relative;
  z-index: 3; }
  .btn_let span {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    background: linear-gradient(to bottom, #58b0f4 0%, #58b0f4 0%, #58b0f4 0%, #58b0f4 0%, #31a3dd 59%, #0f97c9 100%, #207cca 100%);
    z-index: -3; }
  .btn_let:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    background: linear-gradient(to bottom, #54aef1 59%, #54aef1 100%);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1; }
  .btn_let:hover {
    color: #fff; }
    .btn_let:hover:after {
      opacity: 1; }
  .btn_let:before {
    content: '\f075';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #54aef1;
    right: 13px;
    top: 50%;
    transform: translateY(-50%); }

.btn_video {
  background: #fff;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
  padding: 0 90px 0 30px;
  box-shadow: 0 33px 54px 0 rgba(125, 161, 187, 0.26);
  position: relative;
  transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1); }
  .btn_video:hover {
    box-shadow: 0 15px 24px 0 rgba(125, 161, 187, 0.26); }
    .btn_video:hover .icon {
      border: 7px solid #fff;
      background: #3399cc;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.4); }
      .btn_video:hover .icon:before {
        background: #3399cc;
        transform: scale(0, 0); }
    .btn_video:hover .btn_label_big {
      color: #3399cc; }
  .btn_video .btn_label {
    color: #afb0b1;
    font-size: 14px;
    transition: color .3s;
    line-height: 1.5; }
    .btn_video .btn_label_big {
      text-transform: uppercase;
      color: #9fc14e;
      font-size: 20px;
      font-weight: 900; }
  .btn_video .icon {
    width: 61px;
    height: 61px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms;
    border: 1px solid #dcdcdc; }
    .btn_video .icon:before {
      transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
      content: "";
      width: 43px;
      height: 43px;
      border-radius: 50%;
      background: #8ab206;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
    .btn_video .icon:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 4px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 10px solid #ffffff;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent; }

.link_wr {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 60px; }

.link_maintenance {
  font-size: 18px;
  color: #1d9bd1;
  font-weight: 600;
  position: relative;
  line-height: 1;
  transition: color .3s; }
  .link_maintenance:before {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    content: '\f1cd';
    font-weight: 400;
    font-family: "Font Awesome 5 Free"; }
  .link_maintenance:after {
    position: absolute;
    content: '';
    top: 100%;
    height: 1px;
    opacity: 1;
    width: 100%;
    transition: opacity .3s;
    left: 0;
    background-color: #1d9bd1; }
  .link_maintenance:hover {
    color: #9fc14e; }
    .link_maintenance:hover:after {
      opacity: 0; }

.btn_request {
  font-size: 16px; }

.btn_roсket {
  color: #32495b;
  transition: all .3s;
  font-family: Lato,sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  height: 40px;
  font-size: 16px;
  position: relative;
  margin-left: 0;
  margin-right: 10px;
  padding: 0 10px 0 40px;
  border: 2px solid #50adee; }
  .btn_roсket:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    z-index: -1;
    bottom: 0;
    height: 25px;
    width: 25px;
    text-align: center;
    margin: auto;
    color: #fff;
    background: #50adee;
    border-radius: 50%;
    transition: all .3s; }
  .btn_roсket:hover span {
    color: #fff; }
  .btn_roсket:hover:before {
    transform: scale(15); }
  .btn_roсket span {
    display: inline-block;
    color: #50adee;
    font-size: 16px; }
    .btn_roсket span:before {
      content: '\f135';
      font-weight: 900;
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      height: 25px;
      width: 25px;
      line-height: 25px;
      text-align: center;
      margin: auto;
      color: #fff;
      background: transparent;
      border-radius: 50%;
      transition: all .3s; }

@media only screen and (max-width: 1230px) and (min-width: 1200px) {
  .slick_menu .menu {
    margin-left: 40px; }
  .btn_roсket {
    margin-right: 5px; } }

@media only screen and (max-width: 1200px) {
  .slick_menu .nav .bl_btn .btn_request {
    border: 2px solid #ff9801; }
    .slick_menu .nav .bl_btn .btn_request:hover {
      background-color: transparent;
      color: #ff9801; }
      .slick_menu .nav .bl_btn .btn_request:hover:after {
        color: #ff9801 !important; }
  .slick_menu .nav .bl_btn .btn_roсket {
    background-color: #50adee; }
    .slick_menu .nav .bl_btn .btn_roсket:before {
      content: '\f135';
      font-weight: 400;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 28px; }
    .slick_menu .nav .bl_btn .btn_roсket:hover {
      background-color: transparent;
      color: #50adee; }
      .slick_menu .nav .bl_btn .btn_roсket:hover:before {
        color: #50adee;
        transform: scale(1); }
  .first-screen-home .btn_let {
    align-self: center;
    width: 100%; }
  .first-screen-home .first-screen-content {
    flex-direction: column;
    align-items: center; }
    .first-screen-home .first-screen-content .bl_left {
      text-align: center;
      flex-basis: auto; }
    .first-screen-home .first-screen-content .bl_right {
      flex-basis: auto;
      width: 100%;
      max-width: 795px;
      margin: 90px auto 0; } }

@media only screen and (max-width: 992px) {
  .first-screen-home .person1,
  .first-screen-home .person2,
  .first-screen-home .progress_line {
    display: none; }
  .first-screen-home .first-screen-content .bl_right {
    text-align: center;
    display: block;
    margin-top: 0;
    min-height: 150px; }
  .first-screen-home .mob_img {
    display: inline-block; } }

@media only screen and (max-width: 480px) {
  .first-screen-home .btn_let {
    font-size: 18px;
    padding-right: 25px; } }

@media only screen and (min-height: 800px) {
  .first-screen-home {
    min-height: auto; } }
